const getSize = (imgUrl: string) => {
  const img = new Image();
  const size = { width: 0, height: 0 };
  img.onload = () => {
    size.width = img.width;
    size.height = img.height;
  };
  img.src = imgUrl;
  return size;
};

export default {
  getSize,
};
