






























































































































import { Vue } from "vue-property-decorator";
import documentService from "@/services/documentService";
import ReadingService from "@/services/readingService";
import FieldService from "@/services/FieldService";

import InputModel from "@/models/inputFieldModel";
import Size from "@/models/sizeModel";
import FieldReadValue from "@/models/fieldReadValue";

import imageSizeGetter from "@/helpers/imageSizeGetter";

import SvgIcon from "@/components/SvgIcon.vue";
import Menu from "@/components/Menu.vue";
export default Vue.extend({
  components: {
    SvgIcon,
    Menu,
  },
  data() {
    return {
      searching: true,
      form: [] as InputModel[],
      copyForm: [] as InputModel[],
      currentInput: {} as InputModel,
      currentFieldIndex: 0,
      fieldsRead: [] as FieldReadValue[],
      totalField: 0,
      isLastField: false,
      isLastFieldRequired: false,
      sending: false,
      document: {} as { image: string; type: string },
      ctx: {} as CanvasRenderingContext2D,
      imageWrapper: {} as HTMLElement,
      ctxReady: false,
      rectStart: {} as Touch | null,
      rectEnd: {} as Touch | null,
      imageWrapperScrollTop: 0,
      imageWrapperScrollLeft: 0,
      canDraw: false,
      imageSize: {} as Size,
      errorMessage: "",
      dataSample: [] as string[],
    };
  },
  methods: {
    onKeyboardbGoSubmit(e: KeyboardEvent) {
      // alert(e)
      const key = e.charCode || e.keyCode || 0;
      if (key == 13) {
        //set the focus to one of the input fields
        (document.activeElement as HTMLInputElement).blur();
        if (!this.isLastField) this.nextField();
        e.preventDefault();
      }
    },
    buildField(item: FieldReadValue) {
      return {
        value: item.value || "",
        x: item.x || 0,
        y: item.y || 0,
        width: item.width || 0,
        height: item.height || 0,
      };
    },
    onSubmit() {
      const responseCache = "response-cache-v2";
      //process to save document
      if (!this.sending) {
        let fields = {};
        this.fieldsRead.forEach((item) => {
          const field = this.buildField(item);
          fields = {
            ...fields,
            [item.code]: field,
          };
        });
        this.form.forEach((item) => {
          const element = {} as FieldReadValue;
          const field = this.buildField(element);
          fields = {
            ...fields,
            [item.code]: field,
          };
        });
        const result = {
          documentId: this.id,
          fields,
        };
        /* */
        // Clear request cache, to delete all cached documents.
        caches
          .delete(responseCache)
          .then(() => console.log("Service Worker: Clearing Old Cache"));
        /* */
        this.sending = true;
        ReadingService.save(result)
          .then(() => {
            this.$router.push({ path: "/documents" });
          })
          .catch(() => {
            this.sending = false;
          });
      }
    },
    previousPage() {
      this.$router.go(-1);
    },
    startHandler(event: TouchEvent) {
      this.canDraw = true;
      this.rectStart = event.changedTouches[0];
      this.imageWrapperScrollLeft = this.imageWrapper.scrollLeft;
      this.imageWrapperScrollTop = this.imageWrapper.scrollTop;
    },
    diseableScroll(top: number, left: number) {
      // if any scroll is attempted, set this to the previous value
      this.imageWrapper.onscroll = () => {
        this.imageWrapper.scrollTo(top, left);
      };
    },
    moveHandler(event: TouchEvent) {
      if (this.canDraw && this.ctxReady && this.rectStart) {
        //fix the size of the image during croping
        this.diseableScroll(
          this.imageWrapperScrollLeft,
          this.imageWrapperScrollTop
        );
        this.rectEnd = event.changedTouches[0];
        // clear canvas
        this.clearCanvas();
        // draw rect
        this.ctx.beginPath();
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "blue";
        this.ctx.rect(
          this.rectStart.pageX -
            this.imageWrapper.offsetLeft +
            this.imageWrapper.scrollLeft,
          this.rectStart.pageY -
            this.imageWrapper.offsetTop +
            this.imageWrapper.scrollTop,
          this.rectEnd.pageX - this.rectStart.pageX,
          this.rectEnd.pageY - this.rectStart.pageY
        );
        this.ctx.stroke();
      }
    },
    endHandler() {
      this.canDraw = false;
      this.imageWrapper.onscroll = () => {
        console.log("End handler");
      };
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.imageSize.width, this.imageSize.height);
    },
    nextField() {
      if (this.ctxReady) {
        if (
          this.currentInput.value &&
          this.rectStart &&
          this.rectEnd &&
          this.rectEnd.pageX
        ) {
          const x =
            this.rectStart.pageX -
            this.imageWrapper.offsetLeft +
            this.imageWrapperScrollLeft;
          const y =
            this.rectStart.pageY -
            this.imageWrapper.offsetTop +
            this.imageWrapperScrollTop;
          const width = this.rectEnd.pageX - this.rectStart.pageX;
          const height = this.rectEnd.pageY - this.rectStart.pageY;
          const bottomRightX = x + width;
          const bottomRightY = y + height;
          const value = this.currentInput.value;

          //clear the canvas
          this.clearCanvas();

          this.fieldsRead.push({
            name: this.currentInput.name,
            code: this.currentInput.code,
            value,
            x,
            y,
            width,
            height,
            bottomRightX,
            bottomRightY,
          });
          this.form.splice(this.currentFieldIndex, 1);
          this.rectStart = null;
          this.rectEnd = null;
          this.isLastFieldRequired = this.checkRequiredFields();

          if (this.form.length == 0) {
            this.isLastField = true;
            this.placeRectangleRecap();
            this.errorMessage = "";
          } else {
            // move to the next step
            this.currentFieldIndex = 0;
            this.assignActualInput(this.currentFieldIndex);
            this.errorMessage = "";
          }
        } else {
          this.errorMessage = this.currentInput.value
            ? "You need to crop the information you want to read"
            : "You need to enter a value for the information you want to read";
        }
      }
    },
    placeOneRectangle(item: FieldReadValue) {
      this.ctx.beginPath();
      this.ctx.strokeStyle =
        "#00" + Math.floor(Math.random() * 65535).toString(16);
      this.ctx.rect(item.x, item.y, item.width, item.height);
      this.ctx.stroke();
    },
    placeRectangleRecap() {
      if (this.ctxReady) {
        this.ctx.lineWidth = 2;
        const values = Object.values(this.fieldsRead) as FieldReadValue[];
        values.forEach((item: FieldReadValue) => {
          this.placeOneRectangle(item);
        });
      }
    },
    selectField(index: number) {
      this.currentFieldIndex = index;
      this.currentFieldIndex;
      this.assignActualInput(index);
      //empty the message error content when switching to another field
      this.errorMessage = "";
    },
    deleteField(index: number) {
      //remove the element from the list and assign it to a variable
      const fieldReadElement = this.fieldsRead.splice(index, 1)[0];

      //get the fields properties
      const fieldReadModel = this.copyForm.find((element) => {
        return element.name == fieldReadElement.name;
      });

      //add field to the form
      if (fieldReadModel) {
        if (this.form.length == 0) {
          this.clearCanvas();
          this.isLastField = false;
        }
        this.form.push(fieldReadModel);
        this.form.sort(documentService.sortBaseOnRequired);
        this.assignActualInput(0);
        this.isLastFieldRequired = this.checkRequiredFields();
      }
    },
    checkRequiredFields() {
      return this.form.find((element) => element.required === true)
        ? false
        : true;
    },
    assignActualInput(index: number) {
      this.dataSample = [];
      this.currentInput = this.form[index];
      if (this.currentInput.prefilled) {
        //retrieve the old values read for this fields
        FieldService.getReadValues(this.document.type, this.currentInput.code)
          .then((res) => {
            this.dataSample = res.data;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
  mounted() {
    documentService.getDocumentById(this.id).then((result) => {
      const image = result.data.document.location;
      const type = result.data.document.type;
      this.document = { image, type };
      this.form = documentService.buildForm(result.data.fields);
      this.copyForm = JSON.parse(JSON.stringify(this.form));
      this.totalField = this.form.length;
      this.assignActualInput(0);
      if (result.data.document.width && result.data.document.height) {
        this.imageSize.width = result.data.document.width;
        this.imageSize.height = result.data.document.height;
      } else {
        this.imageSize = imageSizeGetter.getSize(image);
      }

      this.searching = false;
      //set the margin top of the form
      const form = document.querySelector(".form") as HTMLElement;
      if (form) form.style.paddingTop = this.imageSize.height + 50 + "px";
    });

    //this is for the immplementation of the cropper
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    this.imageWrapper = document.querySelector(".image-wrapper") as HTMLElement;

    if (canvas) {
      const context: CanvasRenderingContext2D | null = canvas.getContext("2d");

      if (context != null) {
        this.ctx = context;
        this.ctxReady = true;
      }
    }
  },
  props: ["id"],
});
